<template>
  <div class="TestBlock">
    <div class="TestBlock__btn-container pb-4">
      <v-btn
        @click="run()"
        color="success"
        :disabled="isWorkflowRunning"
        :loading="testRunButtonLoading"
      >
        <v-icon class="mr-3" right>mdi-bug-check</v-icon>
        Test Run
      </v-btn>

      <v-btn
        color="success"
        :disabled="!canWorkflowBeSaved || isWorkflowRunning || isWorkflowSaving"
        @click="run(true)"
        :loading="prodRunButtonLoading"
        elevation="0"
        outlined
      >
        <v-icon class="mr-3" right>mdi-bug-check</v-icon>Production Run
      </v-btn>
    </div>

    <h3
      class="py-3 px-4"
      v-if="
        running.validation?.validation_status === workflowStatus.SUCCESS &&
        (running.vm_status === workflowStatus.SUCCESS ||
          running.vm_status === workflowStatus.SKIPPED) &&
        hideSettings
      "
    >
      <workflow-status-icon :status="running.workflow_status" class="mr-1" />
      Running workflow in
      {{ running.runningInProductionMode ? 'production mode' : 'test mode' }}
    </h3>

    <v-card
      outlined
      class="mx-4 mb-4 TestBlock__run-status"
      v-if="headerText && hideSettings"
      rounded="lg"
    >
      <v-card-title class="TestBlock__run-status-title">
        <workflow-status-icon :status="running.workflow_status" class="mr-1" />
        {{ headerText }}
      </v-card-title>

      <v-list flat>
        <v-list-item>
          <v-list-item-icon>
            <workflow-status-icon
              :status="running.validation?.validation_status"
            />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Validation</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <workflow-status-icon :status="running.validation?.actions" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Actions</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="
            running.validation?.schedule !== workflowStatus.SKIPPED &&
            running.validation?.schedule !== workflowStatus.NOT_STARTED
          "
        >
          <v-list-item-icon>
            <workflow-status-icon :status="running.validation?.schedule" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Schedule</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>

    <v-card
      outlined
      class="mx-4 mb-4 TestBlock__response"
      rounded="lg"
      v-if="
        running.vm_status &&
        running.vm_status !== workflowStatus.NOT_STARTED &&
        running.vm_status !== workflowStatus.SKIPPED &&
        hideSettings
      "
    >
      <v-card-text>
        <workflow-status-icon :status="running.vm_status" />
        Testing connection with the remote bot
      </v-card-text>
    </v-card>

    <v-card
      outlined
      class="mx-4 mb-4 TestBlock__response"
      rounded="lg"
      v-if="
        running.validation?.validation_status === workflowStatus.SUCCESS &&
        (running.vm_status === workflowStatus.SUCCESS ||
          running.vm_status === workflowStatus.SKIPPED) &&
        hideSettings
      "
    >
      <v-expansion-panels accordion flat multiple>
        <v-expansion-panel
          v-for="(action, index) in actionsStatuses"
          :key="index"
        >
          <v-expansion-panel-header class="px-4">
            <div>
              <workflow-status-icon :status="action.status" class="mr-1" />
              Step {{ index + 1 }}.{{ action.action_name }}
            </div>
            <template v-slot:actions>
              <template v-if="action.finish">
                <span class="text-caption grey--text">{{
                  formatDuration(action.start, action.finish)
                }}</span>
              </template>
              <template v-else-if="action.progress">
                <span class="text-caption grey--text">{{
                  action.progress
                }}</span>
              </template>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <input-output-viewer
              :action="action"
              :isServerless="workflow.isServerless"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>

    <v-btn
      @click="continueWorkflow"
      v-if="isBreakPoint && running.workflow_status === workflowStatus.PAUSED"
      color="success"
      class="mx-4 mb-4 TestBlock__response"
      :loading="
        resumeWorkflowLoading ||
        (!isSelectedVersionRun &&
          !blockTestRunLoading &&
          !running.runningInProductionMode &&
          isWorkflowRunning)
      "
    >
      CONTINUE
    </v-btn>

    <v-btn
      class="mx-4 mb-4 TestBlock__response"
      v-if="!isWorkflowRunning && hideSettings && !updateWorkflowLoading"
      @click="showSettings()"
      color="success"
    >
      <v-icon class="mr-3" right>mdi-arrow-left</v-icon>
      BACK
    </v-btn>

    <div class="AutomationDetails__content">
      <router-view />

      <action-prompt
        v-model="showProductionWarnDialog"
        v-if="showProductionWarnDialog"
        :action-text="dialogActionText"
        :action="handleRunInProduction"
        :header="dialogTitle"
        :body="dialogBodyText"
        :width="500"
        action-type="primary"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import * as moment from 'moment';
import ActionPrompt from '@/components/modals/action-prompt/action-prompt.vue';
import { workflowStatus } from '@/util/workflow-statuses';
import WorkflowStatusIcon from '@/components/workflow-status-icon/workflow-status-icon.vue';
import InputOutputViewer from '@/components/io-viewer/io-viewer.vue';

const {
  mapActions: workflowDetailsActions,
  mapGetters: workflowDetailsGetters,
  mapMutations: workflowDetailsMutations,
} = createNamespacedHelpers('workflows/details');
const {
  mapActions: workflowHistoryActions,
  mapGetters: workflowHistoryGetters,
} = createNamespacedHelpers('workflows/history');
const { mapGetters: customerGetters } = createNamespacedHelpers('customer');
const { mapActions: sharedActions } = createNamespacedHelpers('shared');

export default {
  name: 'TestBlock',
  components: {
    WorkflowStatusIcon,
    ActionPrompt,
    InputOutputViewer,
  },
  data() {
    return {
      workflowStatus: workflowStatus,
      collapsedDataIds: [],
      productionWarnDialog: false,
      showProductionWarnDialog: false,
      dialogTitle: 'Run workflow in production mode',
      dialogBodyText: `
      You are going to run the workflow in production mode. In this mode
      REAL data will be used and the process CANNOT be stopped. Are you sure
      you want to run this workflow in production mode?`,
      dialogActionText: 'Run in production',
      blockTestRunLoading: false,
    };
  },
  computed: {
    ...workflowDetailsGetters({
      workflow: 'WORKFLOW',
      canWorkflowBeSaved: 'CAN_WORKFLOW_BE_SAVED',
      saveWorkflowLoading: 'SAVE_WORKFLOW_LOADING',
      updateWorkflowLoading: 'UPDATE_WORKFLOW_LOADING',
      startSettings: 'START_SETTINGS',
      nodes: 'NODES',
      runStatus: 'WORKFLOW_RUNNING_OBJ',
      workflowRunning: 'WORKFLOW_RUNNING',
      workflowStarted: 'WORKFLOW_STARTED',
      checkingWorkflowStatus: 'CHECK_AUTOMATION_WORKFLOW_STATUS_LOADING',
      hideSettings: 'GET_HIDE_SETTINGS',
    }),
    ...workflowHistoryGetters({
      resumeWorkflowLoading: 'RESUME_WORKFLOW_LOADING',
    }),
    ...customerGetters({
      customer: 'CUSTOMER',
      selected_customer: 'SELECTED_CUSTOMER',
    }),
    testRunButtonLoading() {
      if (this.updateWorkflowLoading) {
        return true;
      }
      return (
        !this.isSelectedVersionRun &&
        !this.blockTestRunLoading &&
        !this.running.runningInProductionMode &&
        this.isWorkflowRunning
      );
    },
    prodRunButtonLoading() {
      if (this.updateWorkflowLoading) {
        return true;
      }
      return this.running.runningInProductionMode && this.isWorkflowRunning;
    },
    isSelectedVersionRun() {
      return this.$store.state.workflows.details.openVersionModalFromRun;
    },
    actionsStatuses() {
      if (this.running.actions_statuses) {
        return this.running.actions_statuses;
      } else {
        return [];
      }
    },
    headerText() {
      const prod = this.running.runningInProductionMode;
      switch (this.running.workflow_status) {
        case workflowStatus.IDLE:
        case workflowStatus.PENDING:
          return prod ? 'Running workflow in production...' : 'Testing...';
        case workflowStatus.SUCCESS:
          return prod ? 'Production run successful!' : 'Testing Successful!';
        case workflowStatus.FAILURE:
          return prod ? 'Production run failed!' : 'Testing Failed!';
        case workflowStatus.PAUSED:
          return prod ? 'Workflow Paused' : 'Workflow Paused';
        default:
          return null;
      }
    },
    automationId() {
      return this.$route.params.automationId;
    },
    customerId() {
      if (this.customer.customer_id) {
        return this.selected_customer.customer_id;
      }
      return this.customer.customer_id;
    },
    isNew() {
      return this.automationId === 'new' || !this.automationId;
    },
    isWorkflowRunning() {
      return (
        this.workflowRunning ||
        this.workflowStarted ||
        this.checkingWorkflowStatus ||
        this.running.workflow_status === 'PENDING'
      );
    },
    isWorkflowSaving() {
      return this.saveWorkflowLoading || this.updateWorkflowLoading;
    },
    isBreakPoint() {
      return this.running.breakpoint;
    },
    running() {
      if (this.automationId) {
        return this.runStatus(this.automationId);
      }
      return {};
    },
  },
  watch: {
    isSelectedVersionRun(value) {
      if (!value) {
        this.blockTestRunLoading = true;
        setTimeout(() => {
          this.blockTestRunLoading = false;
        }, 5000);
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.checkWorkflowStatusTimer);
    // TODO: stop workflow status here on page navigation
  },
  methods: {
    ...sharedActions(['postError']),
    ...workflowDetailsActions(['runWorkflow', 'getWorkflowStatus']),
    ...workflowHistoryActions(['resumeWorkflow']),
    ...workflowDetailsMutations({
      openVersionModalFromRun: 'SET_OPEN_VERSION_MODAL_FROM_RUN',
      setRunningOBJ: 'SET_WORKFLOW_RUNNING_OBJ',
      setHideSettings: 'SET_HIDE_SETTINGS',
    }),
    showSettings() {
      this.setHideSettings(false);
    },
    async continueWorkflow() {
      await this.resumeWorkflow({
        storageId: this.running.storageId,
        workflowId: this.workflow.id,
      });
    },
    async run(production = false) {
      this.setHideSettings(true);
      this.runWorkflow({ isProduction: production, isNew: this.isNew });
    },
    handleRunInProduction() {
      this.showProductionWarnDialog = false;
      this.runWorkflow({ isProduction: true, isNew: this.isNew });
    },
    formatDuration(start, finish) {
      const difference = moment(finish).diff(moment(start), 'milliseconds');
      return moment.utc(difference).format('m [min] s [sec]');
    },
    handleShowProductionModal() {
      this.showProductionWarnDialog = true;
    },
    handleRunSelected() {
      this.openVersionModalFromRun(true);
    },
    getFormattedData(id, data) {
      if (!data) {
        return '-';
      } else if (!this.collapsedDataIds.includes(id)) {
        if (Array.isArray(data)) {
          if (data.length <= 1) return data[0] ? `[ ${data[0]} ]` : '[]';
          else return '[ ... ]';
        } else {
          return '{ ... }';
        }
      } else {
        return data;
      }
    },
    collapseData(prefix, id) {
      const item = `${prefix}_${id}`;
      if (this.collapsedDataIds.includes(item)) {
        this.collapsedDataIds.splice(this.collapsedDataIds.indexOf(item), 1);
      } else {
        this.collapsedDataIds.push(item);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './test-block';
</style>
