<template>
  <div class="Workflow-Setting">
    <v-dialog
      :value="settingDialog"
      persistent
      max-width="700"
      @click:outside="settingDialog = false"
    >
      <v-card style="padding: 15px; padding-top: 0px">
        <v-card-title>Workflow Settings</v-card-title>

        <div v-if="IS_SERVERLESS_FEATURE_FLAG_ON === 'true'" class="mb-2">
          <v-switch label="Serverless Mode" v-model="serverlessMode">
            Serverless Mode
          </v-switch>
        </div>

        <div v-if="serverlessMode" class="mb-2">
          <v-switch
            label="Use S3 storage for actions I/O"
            v-model="isS3StorageEnabled"
          >
            Use S3 storage for actions I/O
          </v-switch>
        </div>

        <v-text-field
          class="mb-4"
          outlined
          dense
          label="Bot Name"
          hide-details="auto"
          v-model="workflowName"
        />

        <v-textarea
          outlined
          label="Bot Description"
          v-model="workflowDescription"
          class="mb-3"
        />

        <v-select
          outlined
          dense
          :value="startSettings.trigger_type"
          @change="(val) => onStartSettingsChange('trigger_type', val)"
          :items="triggerTypes"
          item-text="name"
          item-value="value"
          label="Choose how this bot will be triggered"
          class="mb-4"
        />
        <div class="mt-2" v-if="startSettings.trigger_type === 'event'">
          <v-select
            class="mt-2"
            outlined
            dense
            :value="startSettings.trigger_subtype"
            @change="(val) => onStartSettingsChange('trigger_subtype', val)"
            :items="triggerSubtypes"
            item-text="name"
            item-value="value"
            label="Choose event type"
          ></v-select>

          <div v-if="startSettings.trigger_subtype && showActivators">
            <Activators :type="startSettings.trigger_subtype" />
          </div>
        </div>

        <div v-else-if="startSettings.trigger_type === 'cron'">
          <v-radio-group
            v-if="startSettings.trigger_type"
            :value="startSettings.period"
            @change="(val) => onStartSettingsChange('period', val)"
            :mandatory="false"
          >
            <v-radio label="Every Day" value="day"></v-radio>
            <v-radio label="Every week" value="week"></v-radio>
            <v-radio label="Every month" value="month"></v-radio>
            <v-radio label="Custom" value="custom"></v-radio>
          </v-radio-group>

          <v-divider class="divider"></v-divider>

          <div v-if="selectedPeriod === 'day'">
            <div v-for="(day, index) in weekDays" :key="index">
              <v-checkbox
                v-model="selectedDaysModel"
                :label="day.name"
                :value="day.id"
              ></v-checkbox>
            </div>
          </div>

          <v-radio-group
            v-if="selectedPeriod === 'week'"
            v-model="selectedWeekdayModel"
          >
            <v-radio
              v-for="(day, ind) in weekDays"
              :key="ind"
              :label="day.name"
              :value="day.id"
            ></v-radio>
          </v-radio-group>

          <v-select
            outlined
            class="mb-1"
            dense
            v-if="selectedPeriod === 'month'"
            :value="startSettings.day"
            @change="(val) => onStartSettingsChange('day', val)"
            :items="monthDays"
            label="Day"
          ></v-select>

          <v-text-field
            v-if="selectedPeriod === 'custom'"
            outlined
            dense
            label="Cron Expression"
            :value="startSettings.custom"
            @change="(val) => onStartSettingsChange('custom', val)"
            placeholder="Cron Expression"
            :error="!isCronValid"
          ></v-text-field>
          <div v-else>
            <v-menu
              v-if="startSettings.trigger_type"
              ref="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  outlined
                  dense
                  readonly
                  :value="startSettings.time_at"
                  placeholder="When should this bot start"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                :value="startSettings.time_at"
                @change="(val) => onStartSettingsChange('time_at', val)"
                full-width
                @click:minute="$refs.menu2.save(startSettings.time_at)"
              ></v-time-picker>
            </v-menu>
          </div>
        </div>

        <!-- <div v-else-if="startSettings.trigger_type === 'manual'">
      <p class="mb-8 text-center">
        This workflow will only run once the "RUN WORKFLOW" button in the header
        is clicked
      </p>
    </div> -->

        <v-alert
          outlined
          type="info"
          dense
          v-else-if="startSettings.trigger_type === 'manual'"
          class="mt-5"
        >
          This workflow will only run once the "RUN WORKFLOW" button in the
          header is clicked
        </v-alert>

        <v-switch
          class="mt-5 mb-5"
          label="Workflow Alerts"
          v-model="alertEnabled"
        >
          Workflow Alerts
        </v-switch>
        <div v-if="alertEnabled" class="mb-10">
          <v-row class="align-center">
            <p class="mr-5 ml-5">Success rate</p>
            <v-text-field
              class="mb-4"
              outlined
              dense
              label="Rate Condition"
              hide-details="auto"
              v-model="alertRateCondition"
            />
          </v-row>
          <v-row dense align="center">
            <v-col cols="auto" class="mr-auto">
              <p class="text-subtitle-1 mb-0 ml-2">Status</p>
            </v-col>
            <v-col cols="auto">
              <v-btn
                v-if="showAddStatus"
                @click="addStatus()"
                text
                color="primary"
                dense
              >
                + Add
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="ml-2 mr-3">
            <div
              class="Workflow-Setting__param-row mb-6"
              v-for="(status, i) in alertSettings.status"
              :key="i"
            >
              <v-select
                outlined
                dense
                :value="status"
                @change="
                  (val) => {
                    alertSettings.status[i] = val;
                  }
                "
                :items="workflowStatuses"
                item-text="name"
                item-value="value"
                label="Alert if status in:"
                class="Workflow-Setting__param-row--field mr-2"
              />
              <v-btn
                class="Workflow-Setting__param-row--icon-btn"
                icon
                @click="deleteMetadata(i)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </v-row>
          <v-row dense align="center">
            <v-col cols="auto" class="mr-auto">
              <p class="text-subtitle-1 mb-0 ml-2">Ping Users</p>
            </v-col>
            <v-col cols="auto">
              <v-btn @click="addUserId()" text color="primary" dense>
                + Add
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="ml-2 mr-3">
            <div
              class="Workflow-Setting__param-row mb-6"
              v-for="(userId, i) in alertSettings.pingUsers"
              :key="i"
            >
              <v-text-field
                outlined
                dense
                :value="userId"
                @change="
                  (val) => {
                    alertSettings.pingUsers[i] = val;
                  }
                "
                item-text="name"
                item-value="value"
                label="User ID"
                class="Workflow-Setting__param-row--field mr-2"
              />
              <v-btn
                class="Workflow-Setting__param-row--icon-btn"
                icon
                @click="deleteUserId(i)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </v-row>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="settingDialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div style="max-width: fit-content; margin-inline: auto">
      <v-btn
        color="primary"
        @click.stop="settingDialog = true"
        outlined
        style="width: 240px"
      >
        EDIT SETTINGS
      </v-btn>
    </div>

    <version-setting />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import * as cronParser from 'cron-parser';
import { actionsTypes } from '@/util/action-types';
import Activators from '../external-activators/external-activators.vue';
import * as moment from 'moment-timezone';
import VersionSetting from '@/components/connector-settings/start-settings/version-setting/version-setting.vue';
const {
  mapGetters: workflowDetailsGetters,
  mapActions: workflowDetailsActions,
  mapMutations: workflowDetailsMutations,
} = createNamespacedHelpers('workflows/details');
const { mapGetters: customerGetters } = createNamespacedHelpers('customer');

export default {
  name: 'start-settings',
  data() {
    return {
      settingDialog: false,
      IS_SERVERLESS_FEATURE_FLAG_ON: 'false',
      workflowStatuses: ['FAILURE', 'CANCELLED', 'PAUSED'],
      localTZ: 'UTC',
      showAddStatus: true,
      isCronValid: false,
      triggerTypes: [
        { name: 'Incoming Event', value: 'event' },
        { name: 'Scheduled Job', value: 'cron' },
        { name: '"Run Workflow" connector', value: 'run_workflow_connector' },
        { name: 'Manual Trigger', value: 'manual' },
      ],
      triggerSubtypes: [
        { name: 'Incoming Jot Form', value: 'incoming_jot_form' },
        { name: 'Incoming Twilio Message', value: 'incoming_twilio' },
        { name: 'Incoming Insurance Task', value: 'incoming_insurance_task' },
        { name: 'Incoming DropIn', value: 'incoming_dropin' },
        { name: 'Incoming General', value: 'incoming_general' },
      ],
      ewaTypes: [
        'incoming_jot_form',
        'incoming_twilio',
        'incoming_insurance_task',
        'incoming_dropin',
        'incoming_general',
      ],
      weekDays: [
        { name: 'Sunday', id: '0' },
        { name: 'Monday', id: '1' },
        { name: 'Tuesday', id: '2' },
        { name: 'Wednesday', id: '3' },
        { name: 'Thursday', id: '4' },
        { name: 'Friday', id: '5' },
        { name: 'Saturday', id: '6' },
      ],
    };
  },
  components: {
    VersionSetting,
    Activators,
  },
  created() {
    this.IS_SERVERLESS_FEATURE_FLAG_ON =
      process.env.VUE_APP_IS_SERVERLESS_FEATURE_FLAG_ON;
  },
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
    }),
    ...workflowDetailsGetters({
      startSettings: 'START_SETTINGS',
      workflow: 'WORKFLOW',
      nodes: 'NODES',
      alertSettings: 'ALERT_SETTINGS',
      s3StorageEnabled: 'S3_STORAGE_ENABLED',
      workflowVersions: 'WORKFLOW_VERSIONS',
      workflowVersionsLoading: 'WORKFLOW_VERSIONS_LOADING',
      publishedVersion: 'WORKFLOW_PUBLISHED_VERSION',
      selectedVersion: 'WORKFLOW_SELECTED_VERSION',
    }),
    selectedPeriod() {
      return this.startSettings.trigger_type && this.startSettings.period;
    },
    monthDays() {
      return Array(31)
        .fill()
        .map((e, i) => i + 1);
    },
    selectedDaysModel: {
      get() {
        return [...this.startSettings.week];
      },
      set(value) {
        this.setStartSettings({
          week: value,
        });
      },
    },
    alertEnabled: {
      set(val) {
        this.alertSettings.enabled = val;
        this.onUpdate(val, 'alertSettings.enabled');
        this.onStartSettingsChange('alertSettings', this.alertSettings);
        this.deboundedEditWorkflowProperty({
          key: 'alertSettings',
          value: this.alertSettings,
        });
      },
      get() {
        return this.alertSettings.enabled;
      },
    },
    alertRateCondition: {
      set(val) {
        this.alertSettings.rateCondition = val;
        this.onUpdate(val, 'alertSettings.rateCondition');
        this.onStartSettingsChange('alertSettings', this.alertSettings);
        this.deboundedEditWorkflowProperty({
          key: 'alertSettings',
          value: this.alertSettings,
        });
      },
      get() {
        return this.alertSettings.rateCondition;
      },
    },
    selectedWeekdayModel: {
      get() {
        return this.startSettings.week[0];
      },
      set(value) {
        this.setStartSettings({
          week: [value],
        });
      },
    },
    serverlessMode: {
      get() {
        return this.workflow.isServerless;
      },
      set(value) {
        this.deboundedEditWorkflowProperty({ key: 'isServerless', value });
      },
    },
    isS3StorageEnabled: {
      get() {
        return this.s3StorageEnabled;
      },
      set(value) {
        this.setS3StorageEnabled(value);
        this.onStartSettingsChange('s3StorageEnabled', this.s3StorageEnabled);
        this.deboundedEditWorkflowProperty({ key: 's3StorageEnabled', value });
      },
    },
    workflowName: {
      get() {
        return this.workflow.name;
      },
      set(value) {
        this.deboundedEditWorkflowProperty({ key: 'name', value });
      },
    },
    workflowDescription: {
      get() {
        return this.workflow.description;
      },
      set(value) {
        this.deboundedEditWorkflowProperty({ key: 'description', value });
      },
    },
    automationId() {
      return this.$route.params.automationId;
    },
    isNew() {
      return this.automationId === 'new' || !this.automationId;
    },
    actionsTypes() {
      const name = this.customer.CustomersFeature.data_source_name;
      const at = actionsTypes(name);
      const trigger_type = this.startSettings.trigger_type;

      return cloneDeep(at).filter((action) => {
        return !(
          action.action_type === 'human_in_the_loop' &&
          trigger_type &&
          trigger_type !== 'manual'
        );
      });
    },
    showActivators() {
      return this.ewaTypes.includes(this.startSettings.trigger_subtype);
    },
  },
  watch: {
    selectedPeriod(next, prev) {
      if (prev && next && prev !== next) {
        this.onStartSettingsChange('week', []);
      }
    },
    'startSettings.trigger_type'() {
      this.sedDefaultNode();
    },
    'startSettings.trigger_subtype'() {
      this.sedDefaultNode();
    },
  },
  methods: {
    ...workflowDetailsActions([
      'setStartSettings',
      'editWorkflowProperty',
      'setTriggerType',
      'insertNode',
      'removeNode',
      'setWorkflowVersion',
      'getWorkflowVersions',
    ]),
    ...workflowDetailsMutations({
      setSelectedNodeOption: 'SET_SELECTED_NODE_OPTIONS',
      setS3StorageEnabled: 'SET_S3_STORAGE_ENABLED',
      openVersionModalFromOverwrite: 'SET_OPEN_VERSION_MODAL_FROM_OVERWRITE',
      openVersionModalFromRun: 'SET_OPEN_VERSION_MODAL_FROM_RUN',
    }),
    formatDate(date) {
      return moment(date).tz(this.localTZ).format('YYYY-MM-DD, h:mm:ss A');
    },
    setLocalTimezone() {
      this.localTZ = moment.tz.guess();
    },
    validateCron(freq = '') {
      try {
        cronParser.parseExpression(freq);
        this.isCronValid = true;
      } catch {
        this.isCronValid = false;
      }
    },
    onStartSettingsChange(key, value) {
      if (key === 'custom') this.validateCron(value);

      this.setStartSettings({
        [key]: value,
      });
    },
    removeDefaultNode() {
      const defaultNodes = this.nodes.filter((node) => node.default);
      for (let i = 0; i < defaultNodes.length; ++i) {
        const defaultNode = defaultNodes[i];
        const index = this.nodes.findIndex(
          (node) => node.id === defaultNode.id
        );
        this.setSelectedNodeOption({ index: index });
        this.removeNode(defaultNode);
      }
    },
    sedDefaultNode() {
      const defaultNode = this.nodes.find((node) => node.default);
      const isEventTrigger = this.startSettings.trigger_type === 'event';
      const isRunWorkflowConnector =
        this.startSettings.trigger_type === 'run_workflow_connector';
      const eventSubTrigger = this.startSettings.trigger_subtype;
      if (!isEventTrigger && !isRunWorkflowConnector) {
        this.removeDefaultNode();
        return;
      }
      if (isEventTrigger && eventSubTrigger) {
        if (defaultNode && defaultNode.action_type === eventSubTrigger) {
          return;
        }
        this.removeDefaultNode();
        const action = this.actionsTypes.find(
          (a) => a.action_type === this.startSettings.trigger_subtype
        );
        const options = { index: 0 };
        this.insertNode({ node: action, options });
        return;
      }
      if (isRunWorkflowConnector) {
        const exists = this.nodes.find(
          (node) => node.default && node.action_type === 'workflow_output'
        );
        if (exists) {
          return;
        }
        const inputAction = this.actionsTypes.find(
          (a) => a.action_type === 'workflow_input'
        );
        const outputAction = this.actionsTypes.find(
          (a) => a.action_type === 'workflow_output'
        );
        this.insertNode({
          node: outputAction,
          options: { index: this.nodes.length },
        });
        this.insertNode({ node: inputAction, options: { index: 0 } });
      }
    },
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    addStatus() {
      let status = this.alertSettings.status;
      status.push('FAILURE');
      this.onUpdate(status, 'alertSettings.status');
      this.onStartSettingsChange('alertSettings', this.alertSettings);
      this.deboundedEditWorkflowProperty({
        key: 'alertSettings',
        value: this.alertSettings,
      });
    },
    deleteMetadata(index) {
      const arr = this.alertSettings?.status?.splice(index, 1);
      this.onUpdate(arr, 'alertSettings.status');
      this.onStartSettingsChange('alertSettings', this.alertSettings);
      this.deboundedEditWorkflowProperty({
        key: 'alertSettings',
        value: this.alertSettings,
      });
    },
    addUserId() {
      let status = this.alertSettings.pingUsers || [];
      status.push('');
      this.onUpdate(status, 'alertSettings.pingUsers');
      this.onStartSettingsChange('alertSettings', this.alertSettings);
      this.deboundedEditWorkflowProperty({
        key: 'alertSettings',
        value: this.alertSettings,
      });
    },
    deleteUserId(index) {
      const arr = this.alertSettings?.pingUsers?.splice(index, 1);
      this.onUpdate(arr, 'alertSettings.pingUsers');
      this.onStartSettingsChange('alertSettings', this.alertSettings);
      this.deboundedEditWorkflowProperty({
        key: 'alertSettings',
        value: this.alertSettings,
      });
    },
  },
  mounted() {
    this.deboundedEditWorkflowProperty = debounce(
      this.editWorkflowProperty,
      500
    );
    this.validateCron(this.startSettings.trigger);
    this.setLocalTimezone();
    if (this.isNew) {
      this.settingDialog = true;
    }
  },
};
</script>

<style scoped lang="scss">
@import './start-settings';
</style>
