<template>
  <div>
    <div>
      <div>
        <v-select
          class="mb-3 mt-2"
          outlined
          dense
          :value="selectedNode.settings.bucket"
          @input="(val) => onUpdate(val, 'settings.bucket')"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="Bucket"
          clearable
        ></v-select>

        <v-select
          class="mb-3 mt-2"
          outlined
          dense
          :value="selectedNode.settings.fileKey"
          @input="(val) => onUpdate(val, 'settings.fileKey')"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="File Key"
          clearable
        ></v-select>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'aws-s3-download',
  props: {
    availableInputFields: { required: true },
  },
  components: {},
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    settings() {
      return this.selectedNode.settings;
    },
    showAddMetadataBtn() {
      const metadata = this.settings.metadata;
      return (
        !metadata ||
        !metadata[0] ||
        (metadata[metadata.length - 1].name &&
          metadata[metadata.length - 1].value)
      );
    },
  },
  methods: {
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    addMetadata() {
      let settings = cloneDeep(this.settings);
      if (!settings.metadata) {
        settings.metadata = [];
      }

      settings.metadata.push({
        name: '',
        value: '',
      });
      this.onUpdate(settings, 'settings');
    },
    deleteMetadata(index) {
      const settings = cloneDeep(this.settings);
      settings.metadata.splice(index, 1);
      if (!settings.metadata.length) delete settings.metadata;

      this.onUpdate(settings, 'settings');
    },
  },
};
</script>

<style scoped lang="scss">
@import './aws-claude';
</style>
